import { MarketDealPreviewCreated } from '@/notifications/types.ts';
import { useDealStore, useListingStore } from '@/stores';

const addMarketDeal = (event: MarketDealPreviewCreated) => {
  useDealStore().addMarketDeal(event.deal);
};

const addMarketListing = (event: MarketDealPreviewCreated) => {
  useListingStore().addOrUpdateMarketListing(event.listing);
};

export default [addMarketDeal, addMarketListing];
