import {
  RouteDestination,
  Toast as ToastType,
  ToastPayload,
  ToastStatus,
} from '@/types';
import { defineStore } from 'pinia';

const defaultTimeout = 2000;

let toastIdCounter = 0;

export const useToastStore = defineStore('toasty', {
  state: (): { toasts: ToastType[] } => ({
    toasts: [],
  }),
  actions: {
    createToast(
      title: string,
      message: string,
      status: ToastStatus,
      destination?: RouteDestination
    ) {
      const id = ++toastIdCounter;

      return {
        id,
        title,
        message,
        status,
        destination,
      };
    },
    addToast(payload: ToastPayload, status: ToastStatus) {
      const { title, message, timeout, destination } = payload;

      const newToast = this.createToast(title, message, status, destination);

      this.toasts.push(newToast);

      if (timeout === 0) {
        return;
      }

      setTimeout(() => {
        this.toasts = this.toasts.filter((toast) => toast.id !== newToast.id);
      }, timeout ?? defaultTimeout);
    },
    removeToast(id: number) {
      this.toasts = this.toasts.filter((toast) => toast.id !== id);
    },
    success(payload: ToastPayload) {
      this.addToast(payload, 'SUCCESS');
    },
    error(payload: ToastPayload) {
      this.addToast(payload, 'ERROR');
    },
    warning(payload: ToastPayload) {
      this.addToast(payload, 'WARNING');
    },
    info(payload: ToastPayload) {
      this.addToast(payload, 'INFO');
    },
  },
});
