import { Company, CompanyType } from '@/types';

const buyingTypes: CompanyType[] = ['BUYER', 'TRADER'];
const sellingTypes: CompanyType[] = ['SELLER', 'TRADER'];

export function useUserCanBuy(company: Company): boolean {
  return buyingTypes.some((type) => company.types.includes(type));
}

export function useUserCanSell(company: Company): boolean {
  return sellingTypes.some((type) => company.types.includes(type));
}
