import { useFilterStore } from '@/stores';
import { ListingDetail } from '@/types';
import { DealPreview } from '@/types/deal';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export const useDealStore = defineStore('deals', () => {
  const filterStore = useFilterStore();
  const allMarketDeals = ref<DealPreview[]>([]);
  const traded = ref<Record<number, number>>({});

  const marketDeals = computed(() => filterStore.apply(allMarketDeals.value));

  const setMarketDeals = (value: DealPreview[]) =>
    (allMarketDeals.value = value);

  const addMarketDeal = (deal: DealPreview) => {
    const dealExists = allMarketDeals.value.some((item) => item.id === deal.id);

    if (dealExists) {
      // Since deals are static, we only push to the store if it's not already there.
      return;
    }

    allMarketDeals.value.unshift(deal);
  };

  const markAsTraded = (listing: ListingDetail) => {
    traded.value[listing.id] = listing.id;
  };

  const wasTraded = function (listing: ListingDetail) {
    return traded.value[listing.id] === listing.id;
  };

  function clear() {
    allMarketDeals.value = [];
    traded.value = {};
  }

  return {
    addMarketDeal,
    clear,
    marketDeals,
    setMarketDeals,
    markAsTraded,
    wasTraded,
  };
});
