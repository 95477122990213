<template>
  <Form
    id="create-listing-form"
    ref="createListingForm"
    class="flex flex-col"
    :validation-schema="toTypedSchema(formSchema)"
    autocomplete="off"
    @submit="$emit('submit')"
  >
    <Field
      v-slot="{ componentField, errorMessage }"
      v-model="type"
      type="radio"
      name="type"
    >
      <RadioGroup
        class="mb-6 flex space-x-6 text-sm/none"
        v-bind="componentField"
      >
        <RadioGroupOption
          v-for="typeOption in listingTypes"
          :key="typeOption.value"
          v-slot="{ checked }"
          :value="typeOption.value"
          :disabled="isOptionDisabled(typeOption.value)"
          :class="
            isOptionDisabled(typeOption.value)
              ? 'hover:cursor-not-allowed'
              : 'hover:cursor-pointer'
          "
          as="template"
        >
          <div
            class="w-full rounded-md border border-vesper-zinc py-4 pl-6"
            :class="[
              checked
                ? 'bg-vesper-neutral from-zinc-100/20 text-white ease-in-out hover:bg-vesper-neutral hover:bg-gradient-to-r hover:to-vesper-neutral/50'
                : 'hover:bg-zinc-300',
              isOptionDisabled(typeOption.value)
                ? 'bg-zinc-300 opacity-50'
                : '',
            ]"
          >
            <Icon :icon="typeOption.icon" fixed-width class="mr-7" />
            <RadioGroupLabel
              :class="
                isOptionDisabled(typeOption.value)
                  ? 'hover:cursor-not-allowed'
                  : 'hover:cursor-pointer'
              "
            >
              {{ typeOption.label }}
            </RadioGroupLabel>
          </div>
        </RadioGroupOption>
      </RadioGroup>
      <span v-if="errorMessage" class="text-xs text-red-500">
        {{ errorMessage }}
      </span>
    </Field>
    <div class="space-y-4">
      <DropdownInputFloat
        name="product"
        data-testid="product-dropdown-field"
        :disabled="products.length < 1 || action === 'EDIT'"
        :label="$t('common.product')"
        input-id="products"
        :items="products"
        :model-value="product"
        :item-label="(product: Product) => product.name"
        @update:model-value="product = $event"
      />
      <DropdownInputFloat
        name="audience"
        data-testid="audience-dropdown-field"
        :label="$t('audience.title')"
        input-id="audience"
        :items="audiences"
        :model-value="audience"
        :item-label="
          (audience: ListingAudience) => $t(`audience.${type}.${audience}`)
        "
        @update:model-value="audience = $event"
      />
      <GroupMultiSelect
        name="selectedFactories"
        data-testid="selected-factories-dropdown-field"
        :model-value="selectedFactories ?? []"
        :grouped-data="groupedFactoriesByLocation"
        :display-value="(factory: Factory) => $format(factory, 'factory-title')"
        :label="type === 'BID' ? 'approved-product-origins' : 'product-origins'"
        variant="big"
        single-row-options
        @update:model-value="selectedFactories = $event"
      />
      <InputFloat
        v-model="unitPriceValue"
        name="unitPriceValue"
        :label="$t('listing.unit-price')"
        type="number"
        @wheel="$event.target.blur()"
      />
      <span class="text-xs">{{
        $t('listing.price-range', {
          min: $format(product?.priceRange.min, 'money'),
          max: $format(product?.priceRange.max.value, 'number'),
        })
      }}</span>
      <InputFloat
        v-model="volumeAmount"
        name="volumeAmount"
        :label="$t('listing.volume')"
        type="number"
        @wheel="$event.target.blur()"
      />
    </div>

    <!-- SPECS START -->
    <div v-for="(specs, category) in categorisedSpecs" :key="category">
      <div class="flex items-center">
        <span class="mr-1 text-nowrap text-sm text-vesper-neutral/60">
          {{ category }}
        </span>
        <hr class="my-8 w-full" />
      </div>
      <div class="space-y-4">
        <div v-for="spec in specs" :key="spec.id">
          <DropdownInputFloat
            v-if="spec.inputType === 'SINGLE'"
            :name="`${spec.id}`"
            :model-value="specForm[spec.id]"
            :label="spec.description"
            :input-id="`spec_${spec.id}`"
            :items="spec.options"
            :search-props="['description']"
            :item-label="(item: ProductSpecOption) => item.description"
            @update:model-value="selectSpec(spec.id, $event)"
          />
          <DropdownList
            v-if="spec.inputType === 'MULTIPLE'"
            :model-value="specForm[spec.id]"
            name="specs"
            :items="spec.options"
            multiple
            allow-empty
            size="big"
            :value-placeholder="spec.description"
            by="description"
            @update:model-value="selectSpec(spec.id, $event)"
          >
            <template #default="{ item }">
              {{ item.description }}
            </template>
            <template #label>
              <span v-if="Array.isArray(specForm[spec.id])">
                {{
                  (specForm[spec.id] as ProductSpecOption[]).length < 3
                    ? (specForm[spec.id] as ProductSpecOption[])
                        .map((item) => item.description)
                        .join(', ')
                    : $t('filter.spec.count', {
                        spec: category,
                        count: (specForm[spec.id] as ProductSpecOption[])
                          .length,
                      })
                }}
              </span>
            </template>
          </DropdownList>
        </div>
      </div>
    </div>

    <div class="flex items-center">
      <span class="mr-1 text-nowrap text-sm text-vesper-neutral/60">
        {{ $t('listing.shipping-terms') }}
      </span>
      <hr class="my-8 w-full" />
    </div>

    <!-- SPECS END -->
    <div class="space-y-4">
      <DropdownInputFloat
        name="incoterm"
        input-id="incoterm"
        :model-value="incoterm"
        :label="$t('listing.shipment.incoterm')"
        :items="incoterms"
        @update:model-value="incoterm = $event"
      />
      <DropdownInputFloat
        name="deliveryLocation"
        input-id="deliveryLocation"
        :model-value="deliveryLocation"
        :label="$t('listing.shipment.delivery-location')"
        :items="shipment.locations"
        :item-label="(item: DeliveryLocation) => item.name"
        :disabled="shouldRestrictLocation"
        @update:model-value="deliveryLocation = $event"
      />
      <DropdownInputFloat
        name="latestDelivery"
        input-id="latestDelivery"
        :model-value="latestDelivery"
        :label="$t('listing.shipment.latest-delivery')"
        :items="shipment.dates"
        :item-label="(item: LatestDelivery) => $format(item, 'delivery')"
        @update:model-value="latestDelivery = $event"
      />
    </div>
  </Form>
</template>

<script setup lang="ts">
import DropdownInputFloat from '@/components/DropdownInputFloat.vue';
import DropdownList from '@/components/DropdownList.vue';
import GroupMultiSelect from '@/components/GroupMultiSelect.vue';
import Icon from '@/components/Icon.vue';
import InputFloat from '@/components/InputFloat.vue';
import { useIsAllowedIncoterm } from '@/composables';
import { createListingFormSchema } from '@/schemas/listing';
import { productSpecOptionSchema } from '@/schemas/specs';
import { useShipmentStore, useUserStore } from '@/stores';
import {
  CategorisedProductSpec,
  DeliveryLocation,
  Factory,
  GroupedFactories,
  GroupedFactoryOption,
  Incoterm,
  LatestDelivery,
  ListingAudience,
  ListingType,
  Product,
  ProductSpec,
  ProductSpecOption,
  RadioButtonType,
  SelectedSpec,
  ShipmentInfo,
} from '@/types';
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue';
import { toTypedSchema } from '@vee-validate/zod';
import { Field, Form } from 'vee-validate';
import { computed, ComputedRef, PropType, Ref, ref, watch } from 'vue';
import { z, ZodRawShape } from 'zod';

const audiences: ListingAudience[] = ['ANY_PARTY', 'DIRECT_PARTY'];
const createListingForm = ref<typeof Form | null>(null);

watch(
  () => createListingForm.value?.meta.dirty,
  (newVal) => {
    emit('formIsDirty', newVal);
  },
  { deep: true }
);

const props = defineProps({
  categorisedSpecs: {
    type: Object as PropType<CategorisedProductSpec>,
    required: true,
  },
  shipment: {
    type: Object as PropType<ShipmentInfo>,
    required: true,
  },
  listingTypes: {
    type: Array as () => RadioButtonType<ListingType>[],
    required: true,
  },
  products: {
    type: Array as () => Product[],
    required: true,
  },
  factories: {
    type: Array as () => Factory[],
    default: () => [],
  },
  action: {
    type: String as PropType<'CREATE' | 'EDIT'>,
    required: true,
  },
});

const shipmentStore = useShipmentStore();

const incoterms = computed(() => {
  if (!type.value) {
    return [] as Incoterm[];
  }

  const currentType = type.value;

  return props.shipment.incoterms.filter((incoterm) =>
    useIsAllowedIncoterm(currentType, incoterm)
  );
});

const locations = props.shipment.locations;
const euLocation = shipmentStore.euLocation;

const groupedFactoriesByLocation = computed<GroupedFactories[]>(() => {
  return locations
    .map((location) => {
      const factoriesForLocation = props.factories.filter(
        (factory) => factory.locationId === location.id
      );

      return {
        groupKey: location.name,
        groupValues: factoriesForLocation,
      };
    })
    .filter(({ groupValues }) => groupValues.length > 0); // Filter out locations with no factories
});

const specForm: Ref<Record<string, ProductSpecOption | ProductSpecOption[]>> =
  ref({});

const type = defineModel<ListingType>('type');
const audience = defineModel<ListingAudience>('audience');
const volumeAmount = defineModel<number>('volumeAmount');
const unitPriceValue = defineModel<number>('unitPriceValue');
const product = defineModel<Product>('product');
const selectedSpecs = defineModel<SelectedSpec[]>('selectedSpecs');
const selectedFactories =
  defineModel<GroupedFactoryOption[]>('selectedFactories');

const incoterm = defineModel<Incoterm | undefined>('incoterm');
const deliveryLocation = defineModel<DeliveryLocation | undefined>(
  'deliveryLocation'
);
const latestDelivery = defineModel<LatestDelivery | undefined>(
  'latestDelivery'
);

const specList: ComputedRef<{ [k: string]: ProductSpec }> = computed(() =>
  Object.fromEntries(
    Object.values(props.categorisedSpecs)
      .reduce(
        (reduced: ProductSpec[], specs: ProductSpec[]) => reduced.concat(specs),
        []
      )
      .map((spec: ProductSpec) => [spec.id, spec])
  )
);

const selectSpec = (id: number, selected: ProductSpecOption) => {
  specForm.value[id] = selected;

  selectedSpecs.value = Object.entries(specForm.value).map((entry) => ({
    description: specList.value[entry[0]].description,
    options: Array.isArray(entry[1]) ? entry[1] : [entry[1]],
  }));
};

watch(
  () => selectedSpecs.value,
  (duplicatedSpecData) => {
    duplicatedSpecData?.forEach((selectedSpec) => {
      // For each selected spec, find the matching spec in the categorised specs
      const spec = Object.values(props.categorisedSpecs)
        .flat()
        .find((spec) => spec.description === selectedSpec.description);

      if (spec) {
        if (spec.inputType === 'SINGLE') {
          specForm.value[spec.id] = selectedSpec.options[0];
        } else if (spec.inputType === 'MULTIPLE') {
          specForm.value[spec.id] = selectedSpec.options;
        }
      }
    });
  },
  { immediate: true }
);

watch(
  () => product.value,
  () => {
    selectedFactories.value = [];
    selectedSpecs.value = [];
    specForm.value = {};
  }
);

watch(
  () => audience.value,
  () => {
    selectedFactories.value = [];
  }
);

watch(
  () => type.value,
  () => {
    selectedFactories.value = [];
    incoterm.value = undefined;
    deliveryLocation.value = undefined;
  }
);

watch(
  () => incoterm.value,
  () => {
    if (shouldRestrictLocation.value && euLocation) {
      deliveryLocation.value = euLocation;
    }
  }
);

const shouldRestrictLocation = computed(() => {
  return type.value === 'BID' && incoterm.value === 'EXW';
});

const formSchema = computed(() => {
  if (product.value?.priceRange) {
    createListingFormSchema.shape.unitPriceValue = z
      .number({ message: 'Price must be a number' })
      .gte(Math.floor(Number(product.value.priceRange.min.value)))
      .lte(Math.floor(Number(product.value.priceRange.max.value)));
  }

  if (product.value?.volumeRange) {
    createListingFormSchema.shape.volumeAmount = z
      .number({ message: 'Volume must be a number' })
      .gte(Math.floor(Number(product.value.volumeRange.min.amount)))
      .lte(Math.floor(Number(product.value.volumeRange.max.amount)));
  }

  const schema: ZodRawShape = {};
  const specs = Object.values(props.categorisedSpecs).reduce(
    (reduced, item) => reduced.concat(item),
    []
  );

  for (const spec of specs) {
    if (spec.inputType === 'SINGLE') {
      /**
       * Adding dropdown to the formSchema will make sure any dropdown spec will be required.
       * Other than that, checkboxes should not require any selected item, and other types of fields could be also
       * included/excluded later as we need
       */
      schema[spec.id] = productSpecOptionSchema;
    }
  }

  return createListingFormSchema.extend(schema);
});

const emit = defineEmits(['submit', 'formIsDirty']);

const userStore = useUserStore();
const isOptionDisabled = (listingType: ListingType) => {
  return !userStore.hasPermission(`MARKETPLACE/LISTING.${listingType}.CREATE`);
};
</script>
