import { useApi } from '@/api';
import { useIsPublishedListing } from '@/composables';
import { useListingStore, withoutStats } from '@/stores';
import { ListingDetail } from '@/types';

const isActive = (listing: ListingDetail) => {
  const listingStore = useListingStore();

  return (
    listingStore.isActiveListing(listing) && useIsPublishedListing(listing)
  );
};

export default <E>(event: E & { listing: ListingDetail }) => {
  const listing = event.listing;

  if (!isActive(listing)) {
    return useListingStore().addOrUpdateRelevantListing(withoutStats(listing));
  }

  /**
   * Use events to fetch listings might lead to overloading the system,
   * however, stats are computed to the current user, and computing in the backend
   * for each possible user will add a huge complexity to the backend process.
   *
   * USE WHEN: Listing data has to be computed for the specific user
   * DO NOT USE WHEN: update the listing for users not directly interested in listing stats
   */
  useApi()
    .listing.getDetailById(event.listing.id)
    .then((listing) => {
      useListingStore().addOrUpdateRelevantListing(listing);
    });
};
