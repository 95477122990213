import { useIsPublishedListing } from '@/composables';
import { useListingStore } from '@/stores';
import { ListingPreview } from '@/types';

export default <E>(event: E & { listing: ListingPreview }) => {
  const listing = event.listing;

  if (!useIsPublishedListing(listing)) {
    return;
  }

  return useListingStore().addOrUpdateMarketListing(listing);
};
