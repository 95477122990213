import { MainSpecification, Specifications } from '@/types';

export function useMainSpecDescription(
  specs: Specifications | MainSpecification
): string | null {
  if (isMainSpecification(specs)) {
    return Object.values(specs)[0];
  }

  return Object.entries(specs)[0]?.[1][0] ?? null;
}

function isMainSpecification(
  specs: Specifications | MainSpecification
): specs is MainSpecification {
  return (
    Object.keys(specs).length === 1 &&
    typeof Object.values(specs)[0] === 'string'
  );
}
