<template>
  <Modal
    :is-open="isOpen"
    body-class="p-0 max-w-screen-md"
    footer-class="p-0"
    :data-test-id="dataTestId"
    @update:is-open="$emit('update:is-open', $event)"
  >
    <template #header>
      <h2>
        {{ title }}
      </h2>
    </template>
    <template #body>
      <p class="px-6 font-normal">
        {{ message }}
      </p>
    </template>
    <template #footer>
      <div class="flex justify-end space-x-3 p-6">
        <div v-if="hasCancelButton" class="grid grid-cols-2 gap-3">
          <Button
            :variant="buttonCancelVariant"
            @click="$emit('update:is-open', false)"
          >
            <span>{{ buttonCancelLabel }}</span>
          </Button>
          <Button
            :loading="loading"
            :variant="buttonConfirmVariant"
            @click="doConfirm()"
          >
            <span>{{ buttonConfirmLabel }}</span>
          </Button>
        </div>
        <Button
          v-else
          :loading="loading"
          :variant="buttonConfirmVariant"
          @click="doConfirm()"
        >
          <span>{{ buttonConfirmLabel }}</span>
        </Button>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import Button from '@/components/Button.vue';
import Modal from '@/components/Modal.vue';
import { useAsyncState } from '@vueuse/core';
import { t } from 'i18next';
import { PropType } from 'vue';

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  message: {
    type: String,
    required: true,
  },
  isOpen: {
    type: Boolean,
    required: true,
  },
  hasCancelButton: {
    type: Boolean,
    default: true,
  },
  buttonCancelLabel: {
    type: String,
    default: t('modal.cancel'),
  },
  buttonConfirmLabel: {
    type: String,
    default: t('modal.confirm'),
  },
  buttonCancelVariant: {
    type: String as () => 'danger' | 'secondary' | 'light',
    default: 'light',
  },
  buttonConfirmVariant: {
    type: String as () => 'danger' | 'secondary' | 'dark',
    default: 'dark',
  },
  onConfirm: {
    type: Function as PropType<() => Promise<void>>,
    required: true,
  },
  dataTestId: {
    type: String,
    default: 'modal-confirm',
  },
});

defineEmits(['update:is-open']);

const { isLoading: loading, execute: doConfirm } = useAsyncState(
  props.onConfirm,
  null,
  { immediate: false }
);
</script>
