import { z } from 'zod';

export const factorySchema = z.object({
  id: z.number(),
  code: z.string(),
  city: z.string(),
  locationId: z.number(),
  products: z.array(z.number()),
  owner: z.object({
    id: z.number(),
    name: z.string(),
  }),
});
