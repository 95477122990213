import { useUserStore } from '@/stores';
import { Factory, ListingDetail } from '@/types';

export function useFactoriesForListing(
  factories: Factory[],
  listing: ListingDetail
): Factory[] {
  const company = useUserStore().user?.company;

  if (!company) {
    return [];
  }

  const factoryIds =
    company.id === listing.owner.id
      ? listing.factories
      : listing.matches[company.id];

  if (!factoryIds) {
    return [];
  }

  return factories
    .filter((factory) => {
      return factoryIds.includes(factory.id);
    })
    .filter((factory) => {
      return factory.products.includes(listing.productId);
    });
}
