import { RouteRecordRaw } from 'vue-router';

export const appRoutes: RouteRecordRaw[] = [
  {
    path: '',
    redirect: { name: 'overview' },
  },
  {
    path: '/overview/products/:productSlug?',
    name: 'overview',
    component: () => import('@/pages/Overview.vue'),
  },
  {
    path: '/overview/products/:productSlug/listings/:id',
    name: 'listings',
    component: () => import('@/pages/Overview.vue'),
  },
  {
    path: '/your-deals',
    name: 'your-deals',
    component: () => import('@/pages/YourDeals.vue'),
  },
  {
    path: 'account-details',
    name: 'account-details',
    components: {
      default: () => import('@/pages/AccountDetails.vue'),
      sidebar: () => import('@/components/YourAccountSidebar.vue'),
    },
  },
  {
    path: 'your-factories',
    name: 'your-factories',
    components: {
      default: () => import('@/pages/YourFactories.vue'),
      sidebar: () => import('@/components/YourAccountSidebar.vue'),
    },
  },
  {
    path: 'your-partners',
    name: 'your-partners',
    components: {
      default: () => import('@/pages/YourPartners.vue'),
      sidebar: () => import('@/components/YourAccountSidebar.vue'),
    },
  },
  {
    path: 'approved-factories',
    name: 'approved-factories',
    components: {
      default: () => import('@/pages/ApprovedFactories.vue'),
      sidebar: () => import('@/components/YourAccountSidebar.vue'),
    },
  },
  {
    path: 'approved-traders',
    name: 'approved-traders',
    components: {
      default: () => import('@/pages/ApprovedTraders.vue'),
      sidebar: () => import('@/components/YourAccountSidebar.vue'),
    },
  },
];
