<template>
  <GroupMultiSelect
    v-if="incotermGroups"
    name="selectedFactories"
    :model-value="filterStore.filters[props.product.slug].shipments"
    :grouped-data="incotermGroups"
    display-value="name"
    label="incoterm"
    variant="small"
    :single-row-options="false"
    @update:model-value="
      filterStore.filters[props.product.slug].shipments = $event
    "
  />
</template>

<script setup lang="ts">
import GroupMultiSelect from '@/components/GroupMultiSelect.vue';
import { useFilterStore, useShipmentStore } from '@/stores';
import { Product } from '@/types/product';
import { computedAsync } from '@vueuse/core';

defineEmits(['toggle:visible', 'toggle:selection']);
const props = defineProps<{ product: Product }>();

const filterStore = useFilterStore();

const shipmentStore = useShipmentStore();
const incotermGroups = computedAsync(() => shipmentStore.incotermGroups);
</script>
