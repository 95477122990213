import addOrUpdateRelevantListing from '@/notifications/common/add-or-update-relevant-listing.ts';
import fetchListingNegotiations from '@/notifications/common/fetch-listing-negotiations';
import { ProposalCreated } from '@/notifications/types';
import { useNotificationStore, useProductStore, useToastStore } from '@/stores';
import { t } from 'i18next';

const proposalCreatedNotification = (event: ProposalCreated) => {
  const listing = event.listing;
  const product = useProductStore().findById(listing.productId);

  if (!product) {
    return;
  }

  useToastStore().success({
    title: t('notifications.proposal-created.title', {
      type: t(`common.${event.type}`),
    }),
    message: [
      t('notifications.price', { value: event.price }),
      t('notifications.volume', { value: event.volume }),
    ].join(', '),
    destination: {
      name: 'listings',
      params: {
        productSlug: product.slug,
        id: listing.id,
      },
    },
    timeout: 7000,
  });
};

const refreshListingNegotiations = (event: ProposalCreated) => {
  const listingId = event.listing.id;

  fetchListingNegotiations({ id: listingId });
};

const addProductActivity = (event: ProposalCreated) => {
  useNotificationStore().addActivityIndicator(event.listing.productId);
};

export default [
  proposalCreatedNotification,
  refreshListingNegotiations,
  addProductActivity,
  addOrUpdateRelevantListing<ProposalCreated>,
];
