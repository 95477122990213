import { Filterable } from '@/types/filter';

export default (filterable: Filterable, specs: string[]): boolean => {
  const listingSpecs = Object.values(filterable.specifications).flat();

  if (!specs || specs.length === 0) {
    return true;
  }

  return listingSpecs.some((spec) => specs.includes(spec));
};
