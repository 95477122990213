import addOrUpdateRelevantListing from '@/notifications/common/add-or-update-relevant-listing.ts';
import { NegotiationWithdrawn } from '@/notifications/types';
import { useNegotiationStore } from '@/stores';

const removeNegotiationFromStore = (event: NegotiationWithdrawn) => {
  useNegotiationStore().remove({
    negotiationId: event.negotiationId,
    listingId: event.listing.id,
  });
};

export default [
  removeNegotiationFromStore,
  addOrUpdateRelevantListing<NegotiationWithdrawn>,
];
