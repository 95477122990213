import { useApi } from '@/api';
import {
  useEnrichedDetail,
  useEnrichedPreview,
  useIsApprovedListing,
  useIsBidListing,
  useIsNotOngoingListing,
  useIsOfferListing,
} from '@/composables';
import { useFilterStore } from '@/stores';
import {
  EnrichedListingDetail,
  EnrichedListingPreview,
  ListingDetail,
  ListingPreview,
  Product,
} from '@/types';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export const withoutStats = (listing: ListingDetail) => {
  listing.stats = {
    totalOngoing: 0,
    waitingResponse: 0,
    lowestCounterOffer: undefined,
    highestCounterBid: undefined,
  };

  return listing;
};

export const useListingStore = defineStore('listings', () => {
  const api = useApi();

  // Draft listings for the current logged-in user
  const draftListings = ref<EnrichedListingDetail[]>([]);

  // Deal listings for the current logged-in user
  const yourDealListings = ref<EnrichedListingDetail[]>([]);
  const filteredYourDealListings = computed(() =>
    useFilterStore().apply(yourDealListings.value)
  );

  // Relevant listings for the current logged-in user
  const relevantListings = ref<EnrichedListingDetail[]>([]);
  const filteredRelevantListings = computed(() =>
    useFilterStore().apply(relevantListings.value)
  );

  // All listings that the user is current involved in (including their own)
  const activeHistory = ref<Record<number, number>>({});
  const activeListings = computed(() => {
    return filteredRelevantListings.value.filter((listing) => {
      const published = listing.status === 'PUBLISHED';
      const ongoing = listing.stats.totalOngoing > 0;
      const history = activeHistory.value[listing.id] === listing.id;

      return (listing.isYourListing && published) || ongoing || history;
    });
  });

  const opportunityListings = computed(() => {
    return filteredRelevantListings.value
      .filter(useIsApprovedListing)
      .filter(useIsNotOngoingListing)
      .filter((listing) => activeHistory.value[listing.id] == undefined);
  });

  // All listings for the current market
  const marketListings = ref<EnrichedListingPreview[]>([]);
  const filteredMarketListings = computed(() =>
    useFilterStore().apply(marketListings.value)
  );
  const marketBids = computed(() =>
    filteredMarketListings.value.filter(useIsBidListing)
  );
  const marketOffers = computed(() =>
    filteredMarketListings.value.filter(useIsOfferListing)
  );

  const loadDraftListings = async (product: Product) => {
    const listings = await api.listing.getDrafts(product);

    draftListings.value = listings.map((listing) => useEnrichedDetail(listing));
  };

  const loadRelevantListings = async (product: Product) => {
    const listings = await api.listing.getRelevant(product);

    if (relevantListings.value.length === 0) {
      relevantListings.value = listings.map((listing) =>
        useEnrichedDetail(listing)
      );

      computeActiveHistory();
    } else {
      updateRelevantListings(listings);
    }
  };

  const loadMarketListings = async (product: Product) => {
    const listings = await api.listing.getPreviews(product);

    if (marketListings.value.length === 0) {
      marketListings.value = listings.map((listing) =>
        useEnrichedPreview(listing)
      );
    } else {
      updateMarketListings(listings);
    }
  };

  const updateRelevantListings = (list: ListingDetail[]) => {
    list.reverse().forEach((listing) => addOrUpdateRelevantListing(listing));
  };

  const updateYourDealListings = (list: ListingDetail[]) => {
    list.reverse().forEach((listing) => addYourDealListing(listing));
  };

  const addOrUpdateRelevantListing = (listing: ListingDetail) => {
    if (listing.volume.amount <= 0) {
      /**
       * When the listing gets fulfilled, it will be removed from the store by ListingCompleted handlers.
       * Since there is no guarantee which event will be handled first, this if avoids adding to the store
       * again when the listing was previously removed.
       */
      return;
    }

    const index = relevantListings.value.findIndex(
      (item) => item.id === listing.id
    );
    const enrichedListing = useEnrichedDetail(listing);

    index >= 0
      ? (relevantListings.value[index] = enrichedListing)
      : /**
         * not entirely sure if we should just prepend the missing item or run a
         * sorting function -> listings.value.sort((a, b) => b.id - a.id);
         */
        relevantListings.value.unshift(enrichedListing);

    computeActiveHistory();
  };

  const updateMarketListings = (list: ListingPreview[]) => {
    list.reverse().forEach((listing) => addOrUpdateMarketListing(listing));
  };

  const addOrUpdateMarketListing = (listing: ListingPreview) => {
    if (listing.availableVolume.amount <= 0) {
      return;
    }

    const index = marketListings.value.findIndex(
      (item) => item.id === listing.id
    );
    const enrichedListing = useEnrichedPreview(listing);

    index >= 0
      ? (marketListings.value[index] = enrichedListing)
      : marketListings.value.unshift(enrichedListing);
  };

  const addYourDealListing = (listing: ListingDetail) => {
    const index = yourDealListings.value.findIndex(
      (item) => item.id === listing.id
    );

    if (index >= 0) {
      return;
    }

    yourDealListings.value.push(useEnrichedDetail(listing));
    yourDealListings.value.sort(
      (listingA, listingB) => listingB.id - listingA.id
    );
  };

  const addDraftListing = (listing: ListingDetail) => {
    const index = draftListings.value.findIndex(
      (item) => item.id === listing.id
    );

    if (index >= 0) {
      return;
    }

    draftListings.value.push(useEnrichedDetail(listing));
    draftListings.value.sort((listingA, listingB) => listingB.id - listingA.id);
  };

  const computeActiveHistory = () => {
    activeListings.value.forEach((listing) => {
      activeHistory.value[listing.id] = listing.id;
    });
  };

  const completeListing = (listing: { id: number }) => {
    const relevantIndex = relevantListings.value.findIndex(
      (item) => item.id === listing.id
    );
    const marketIndex = marketListings.value.findIndex(
      (item) => item.id === listing.id
    );

    if (relevantIndex >= 0) {
      const listingEntry = relevantListings.value[relevantIndex];

      listingEntry.status = 'COMPLETED';
      listingEntry.volume.amount = 0;

      relevantListings.value[relevantIndex] = useEnrichedDetail(
        withoutStats(listingEntry)
      );
    }

    if (marketIndex >= 0) {
      const listingEntry = marketListings.value[marketIndex];

      listingEntry.status = 'COMPLETED';
      listingEntry.availableVolume.amount = 0;

      marketListings.value[marketIndex] = useEnrichedPreview(listingEntry);
    }
  };

  const cancelListing = (listing: { id: number }) => {
    const relevantIndex = relevantListings.value.findIndex(
      (item) => item.id === listing.id
    );
    const marketIndex = marketListings.value.findIndex(
      (item) => item.id === listing.id
    );

    if (relevantIndex >= 0) {
      const listingEntry = relevantListings.value[relevantIndex];

      listingEntry.status = 'CANCELED';

      relevantListings.value[relevantIndex] = useEnrichedDetail(
        withoutStats(listingEntry)
      );
    }

    if (marketIndex >= 0) {
      const listingEntry = marketListings.value[marketIndex];

      listingEntry.status = 'CANCELED';

      marketListings.value[marketIndex] = useEnrichedPreview(listingEntry);
    }
  };

  const unpublishListing = (listing: { id: number }) => {
    const relevantIndex = relevantListings.value.findIndex(
      (item) => item.id === listing.id
    );

    if (relevantIndex >= 0) {
      const listingEntry = relevantListings.value[relevantIndex];

      listingEntry.status = 'DRAFT';

      const emptyListing = useEnrichedDetail(withoutStats(listingEntry));

      if (listingEntry.isYourListing) {
        remove(listing);
        addDraftListing(emptyListing);
      } else {
        relevantListings.value[relevantIndex] = emptyListing;
      }
    }

    const marketIndex = marketListings.value.findIndex(
      (item) => item.id === listing.id
    );

    if (marketIndex >= 0) {
      const listingEntry = marketListings.value[marketIndex];

      listingEntry.status = 'DRAFT';

      marketListings.value[marketIndex] = useEnrichedPreview(listingEntry);
    }
  };

  const remove = (listing: { id: number }) => {
    relevantListings.value = relevantListings.value.filter(
      (item) => item.id !== listing.id
    );
    marketListings.value = marketListings.value.filter(
      (item) => item.id !== listing.id
    );
  };

  const removeDraft = (listing: { id: number }) => {
    draftListings.value = draftListings.value.filter(
      (item) => item.id !== listing.id
    );
  };

  const removeFromActiveHistory = (listing: { id: number }) => {
    delete activeHistory.value[listing.id];
  };

  const withdraw = (listing: ListingDetail) => {
    removeFromActiveHistory(listing);

    addOrUpdateRelevantListing(withoutStats(listing));
  };

  const isActiveListing = (listing: ListingDetail) => {
    return activeListings.value.some(
      (item: ListingDetail) => item.id === listing.id
    );
  };

  const clear = () => {
    draftListings.value = [];
    relevantListings.value = [];
    marketListings.value = [];
    activeHistory.value = {};
  };

  return {
    activeListings,
    addDraftListing,
    addOrUpdateMarketListing,
    addOrUpdateRelevantListing,
    addYourDealListing,
    cancelListing,
    clear,
    completeListing,
    draftListings,
    filteredMarketListings,
    filteredRelevantListings,
    isActiveListing,
    loadDraftListings,
    loadMarketListings,
    loadRelevantListings,
    marketBids,
    marketOffers,
    opportunityListings,
    remove,
    removeDraft,
    removeFromActiveHistory,
    unpublishListing,
    updateMarketListings,
    updateRelevantListings,
    updateYourDealListings,
    withdraw,
    filteredYourDealListings,
  };
});
