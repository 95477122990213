import { useEnrichedDetail } from '@/composables';
import { CompanyDealDetailCreated } from '@/notifications/types.ts';
import {
  useDealModalStore,
  useDealStore,
  useListingStore,
  useNegotiationStore,
} from '@/stores';

const dealCreatedNotification = (event: CompanyDealDetailCreated) => {
  useDealModalStore().showModal(event.deal, useEnrichedDetail(event.listing));
};

const addToTradedDeals = (event: CompanyDealDetailCreated) => {
  useDealStore().markAsTraded(event.listing);
};

const removeNegotiationFromStore = (event: CompanyDealDetailCreated) => {
  useNegotiationStore().remove({
    negotiationId: event.negotiationId,
    listingId: event.listing.id,
  });
};

const removeActiveListingFromStore = (event: CompanyDealDetailCreated) => {
  useListingStore().removeFromActiveHistory(event.listing);
};

const addListingToYourDealListings = (event: CompanyDealDetailCreated) => {
  useListingStore().addYourDealListing(event.listing);
};

export default [
  dealCreatedNotification,
  addToTradedDeals,
  removeNegotiationFromStore,
  removeActiveListingFromStore,
  addListingToYourDealListings,
];
