import { CreateListingPanelType, ListingDetail } from '@/types';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const usePanelCreateListingStore = defineStore(
  'panelCreateListing',
  () => {
    const panelIsOpen = ref(false);
    const panelType = ref<CreateListingPanelType>('CREATE');
    const duplicateData = ref<ListingDetail | null>(null);

    const openPanel = (
      data: ListingDetail | null = null,
      type: CreateListingPanelType = 'CREATE'
    ) => {
      if (type !== 'CREATE' && !data) {
        throw new Error(
          'Duplicate data is required for EDIT and DUPLICATE panel types'
        );
      }

      panelType.value = type;
      duplicateData.value = data;
      panelIsOpen.value = true;
    };

    const closePanel = () => {
      panelIsOpen.value = false;
      duplicateData.value = null;
      panelType.value = 'CREATE';
    };

    return {
      closePanel,
      duplicateData,
      openPanel,
      panelIsOpen,
      panelType,
    };
  }
);
