import { Interval } from '@/api/types';
import { useUserStore } from '@/stores';
import { useAsyncState } from '@vueuse/core';
import { computed, Ref, watch } from 'vue';

export const INTERVAL_30_SECONDS = 30 * 1000;

const polls: Record<string, Interval> = {};

export const usePollingState = <T>(
  key: string,
  promise: () => Promise<T>,
  enabled: Ref<boolean>,
  interval: number = INTERVAL_30_SECONDS
) => {
  const userStore = useUserStore();
  const active = computed(() => !userStore.isIdle && enabled.value);
  const controller = useAsyncState(promise, undefined as T, {
    immediate: enabled.value,
    resetOnExecute: false,
  });

  const restart = async () => {
    clearInterval(polls[key]);

    if (active.value) {
      polls[key] = setInterval(controller.execute, interval);

      // We need to immediately trigger the first poll to ensure that,
      // when the `active` state changes, the polling is correctly started/stopped.
      // This does mean that any state change in `active` or `enabled` will trigger a new poll.
      if (!controller.isLoading.value) {
        await controller.execute();
      }
    }
  };

  // We need `immediate: true` to ensure that the polling is started for when `enabled` is set to always be `true`
  // (which wont' trigger `active` to change, meaning the restart never happens).
  watch(active, restart, { immediate: true });

  return {
    state: controller.state,
    error: controller.error,
    restart,
    isReady: controller.isReady,
    isLoading: controller.isLoading,
  };
};

export const clearPolls = () => {
  Object.keys(polls).forEach((key) => clearInterval(polls[key]));
};
